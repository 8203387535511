<template>
    <div class="table-box">
        <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
    </div>
</template>
<script>
    export default {
        data() {
            let _this = this;
            return {
                opt: {
                    search: [],
                    columns: [{
                            label: "密码",
                            key: "keyboardPwd"
                        },
                        {
                            label: "密码名称",
                            key: "name",
                        },
                        {
                            label: "密码时效",
                            key: "pwTimeEffectStr",
                            width: 380
                        },
                        {
                            label: "密码状态",
                            key: "statusStr"
                        },
                        {
                            label: "创建人",
                            key: "senderUsername"
                        },
                        {
                            label: "创建角色",
                            key: "roleName"
                        },
                        {
                            label: "创建时间",
                            key: "createTime"
                        },
                    ]
                },
                roomList: [],
                userInfo: {}
            };
        },
        created() {
            this.userInfo = this.cache.getLS("userInfo");
            this.row = this.cache.get("lockInfo");
        },
        activated() {
            this.row = this.cache.get("lockInfo");
        },
        mounted() {

        },
        methods: {
            onGet(opt) {
                let dto = {
                    roomId: this.row.roomId,
                    pageNo: opt.skip,
                    pageSize: opt.limit,
                    bindId: this.$route.params.id,
                    roleType: 0,
                    type: 'password',
                    ownerType: this.userInfo["roleType"] == '1' ?  null : 'landlord',
                    bindLockTime: this.row.createdDate
                };
                if (this.userInfo["roleType"] == '2' || this.userInfo["roleType"] == '6') {
                    dto.plainUserName = this.userInfo["phoneNum"];
                    dto.userId = this.userInfo["userId"];
                }
                this.post("landlord-service/house/v1/device/additive/history/page", dto, {
                    isUseResponse: true
                }).then(res => {
                    res = JSON.parse(JSON.stringify(res).replace(/list/g, "data"));
                    if (res.data.data.data) {
                        for (let i = 0; i < res.data.data.data.length; i++) {
                            let item = res.data.data.data[i];
                            if (item.attachment) {
                                item.attachment = JSON.parse(item.attachment)
                                item.keyboardPwd = item.attachment.pwd ? '*******' : '--';
                            }
                            item.statusStr = ['生效中','已失效'][item.state]
                            if (item.validTimes == 1) {
                                item.pwTimeEffectStr = '单次' ;
                            } else {
                                let _startDate = item.beginTime;
                                let _endDate = item.endTime;
                                if(!_startDate || !_endDate){
                                    item.pwTimeEffectStr = '永久';
                                }
                                else{
                                    item.pwTimeEffectStr = '限时' + '(' + this.format(_startDate)+ ' ~ ' +this.format(_endDate) + ')';
                                }
                            }
                        }
                    } else {
                        res.data.data.data = []
                    }
                    opt.cb(res.data.data);
                });
            },
        }
    };
</script>
<style lang="scss">
    .table-box {
        height: 620px;
    }
</style>