<template>
  <div class="table-box">
        <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      opt: {
        search: [],
        columns: [
          { label: "编码", key: "id", width: 80 },
          { label: "MAC地址", key: "mac" },
          // { label: "钥匙型号", key: "keyTypeStr" },
          { label: "发钥匙时间", key: "createTime" },
          { label: "发钥匙人", key: "senderUsername" },
          { label: "使用人", key: "userName" },
          { label: "开始时间", key: "beginTime" },
          { label: "结束时间", key: "endTime" },
          { label: "设备端软件版本", key: "firmwareVersion" },
        ],
      },
      roomList: [],
    };
  },
  created() {
    console.log("individualLandlord created!!");
    this.row = this.cache.get("lockInfo");
    
  },
  activated() {
    console.log("individualLandlord activated!!");
     this.row = this.cache.get("lockInfo");
    //  this.$refs.appList.onRefresh();
  },
  mounted(){
   
  },
  methods: {
    onGet(opt) {
      let dto = {
        roomId: this.row.roomId,
        bindId: this.$route.params.id,
        dataFrom:'web',
        pageNum: opt.skip,
        pageSize: opt.limit,
        type:'blue-key'
         };
      this.post("landlord-service/house/v1/device/additive/history/page", dto, {
        isUseResponse: true
      }).then(res => {
        if (res.data.data.data) {
          for (let i = 0; i < res.data.data.data.length; i++) {
            let item = res.data.data.data[i];
            if (item.attachment) {
              item.attachment = JSON.parse(item.attachment)
              item.id = item.attachment.id
              item.mac = item.attachment.mac
              item.userName = item.attachment.userName
              item.firmwareVersion = item.attachment.firmwareVersion
            }
          }
        }else {
          res.data.data.data = []
        }
        opt.cb(res.data.data);
      });
    },
  }
};
</script>
<style lang="scss">
.table-box{
  height: 620px;
}
</style>