<template>
  <div class="lock-log">
    <div class="title">
      <app-header :title="title" :isShowBack="true"></app-header>
    </div>
    <!-- <el-row class="info">
      <el-col :span="24">
        <span class="txt">门锁信息</span>
        <span class="txt">MAC: {{row.mac}}</span>
        <span class="txt">门锁房间: {{row.apartmentName}}{{row.buildings}}栋{{row.roomNo}}房</span>
        <span class="txt">设备端软件版本: {{row.firmwareVersion}}</span>
      </el-col>
    </el-row> -->
    <ul class="tab-ul">
      <li :class="{ 'ative': currentTab == 'tieLockDetails' }" @click="toggleTab('tieLockDetails')">门锁信息</li>
      <li :class="{ 'ative': currentTab == 'doorOpeningLog' }" @click="toggleTab('doorOpeningLog')">开门日志</li>
      <!-- 判断是X1M指纹锁类型时展示指纹信息列表和密码信息列表  -->
      <li v-show="row.isFingerprint == 1" :class="{ 'ative': currentTab == 'fingerprintInfoList' }" @click="toggleTab('fingerprintInfoList')">指纹信息</li>
      <li v-show=" row.type == '32' || row.type == '1009' || row.type == '1012' || row.type == '2003'|| row.type == '1017'" :class="{'ative':currentTab=='ICCardInformation'}" @click="toggleTab('ICCardInformation')">IC卡信息</li>
      <li v-show="row.type == '30' || row.type == '32' || row.type == '1008'|| row.type == '1009' || row.type == '1012' || row.type == '2003' || row.type == '2002'|| row.type == '1017'" :class="{ 'ative': currentTab == 'x1mPasswordInfoList' }" @click="toggleTab('x1mPasswordInfoList')">密码信息</li>
      <li :class="{ 'ative': currentTab == 'individualLandlord' }" @click="toggleTab('individualLandlord')">钥匙信息</li>
    </ul>
    <keep-alive>
      <doorOpeningLog :is="currentTab"></doorOpeningLog>
    </keep-alive>

  </div>
</template>
<script>
import tieLockDetails from './tieLockDetails';
import doorOpeningLog from './doorOpeningLog';
import individualLandlord from './individualLandlord';
import fingerprintInfoList from './fingerprintInfoList';
import x1mPasswordInfoList from './x1mPasswordInfoList';
import ICCardInformation from './ICCardInformation';
export default {
  components: {
    tieLockDetails,
    doorOpeningLog,
    individualLandlord,
    fingerprintInfoList,
    x1mPasswordInfoList,
    ICCardInformation,
  },
  data() {
    let _this = this;
    return {
      currentTab: 'tieLockDetails',
      title: "绑锁详情",
      row: "",
    };
  },
  activated() {
    this.row = this.cache.get("lockInfo");
    this.currentTab = 'tieLockDetails';
  },
  mounted() {
    this.currentTab = 'tieLockDetails';
  },
  methods: {
    toggleTab: function (tab) {
      this.currentTab = tab; // tab 为当前触发标签页的组件名
    },
  }
};
</script>
<style lang="scss">
.lock-log {
  .danger {
    color: #f56c6c;
  }

  .titleSpans {
    padding-bottom: 20px;
  }

  .titleSpan {
    color: #606266;
  }

  .info1 {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #606266;

    .txt {
      margin-right: 20px;
    }
  }

  .info {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #606266;
    background-color: #e4e7ed;

    .txt {
      margin-right: 20px;
    }
  }

  // tab样式
  .tab-ul {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    height: 42px;
    box-sizing: border-box;
    margin-bottom: 40px;
    width: 100%;
    border-bottom: 2px solid #e4e7ed;

    li {
      cursor: pointer;
      padding-left: 18px;
      padding-right: 18px;
      height: 42px;
      line-height: 42px;
      float: left;
      text-align: center;
      margin-right: 13px;
      font-size: 15px;
      background-color: #ffffff;
      color: #303133;
      box-sizing: border-box;
      border-bottom: 2px solid #e4e7ed;

      &.ative {
        // color: #ffffff;
        // background: #5e74f1;
        color: #30BAC1;
        border-bottom: 2px solid #30BAC1;
      }
    }

    &::after {
      content: "";
      display: block;
      clear: both;
    }
  }

  .landlord-manage-box .el-tab-pane {
    height: 650px;
  }
}
</style>
